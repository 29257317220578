const EventEmitter = require('events').EventEmitter;
const RTCatLog = require('../utils/log');
const Detect = require('../platform/detect');
const SdkInfo = require('../sdk_info');

const RTCatError = require('../error');
const RTCatEvent = require('../event');
const Publisher = require('./publisher');
const Subscriber = require('./subscriber');
const CommandQueue = require('../command_queue');
const RemoteStream = require('../rtc/remotestream');
const Peer = require('../rtc/peer');

const QuitReason = {
  Normal: 'normal',
  ChannelClose: 'channel-close',
  Kickout: 'kickout'
}

class SessionUser extends EventEmitter {

  constructor({
    signalServer,
    logServer = null,
    sessionId,
    userId,
    userNumber
  } = {}) {
    super();

    this._sessionId = sessionId;
    this._userId = userId;
    this._userNumber = userNumber;

    this._signalServer = signalServer;
    this._logServer = logServer

    this._tempPubs = new Map();
    this._subscribers = new Map();

    this.state = SessionUser.INIT;

    this._commandQueue = new CommandQueue();

    this._onSignalServerClose = () => {

      this._sendToLogServer({
        type: 'event',
        data: {
          name: 'close', // close event
          reason: QuitReason.ChannelClose
        }
      });

      this.destroy(QuitReason.ChannelClose);
    }

    this._signalServer.internalEvents.on('close', this._onSignalServerClose);

    this.on('#kickout', (args) => {

      this.destroy(QuitReason.Kickout);

      this._sendToLogServer({
        type: 'sig-dw',
        data: {
          name: 'kickout', // kickout
        }
      });
    });

    this.on('#publisher', (args) => {

      this._commandQueue.push({
        queueId: args.userId,
        task: async () => {

          this._sendToLogServer({
            type: 'sig-dw',
            data: {
              name: 'publisher',
              args
            }
          });

          const userId = args.userId;
          const userNumber = args.userNumber;
          const stream_props = args.stream_props;
          const reason = args.reason;

          const subscriber = this._subscribers.get(userId);
          if (subscriber) {

            // an unnormal new publisher 

            this.emit('rtcat-event', new RTCatEvent({
              code: RTCatEvent.EventCode.NEW_PUBLISHER,
              data: {
                reason
              }
            }), userId);

            // reset generation.
            subscriber.generation = 0;
            return this._resubscribe(subscriber, reason);

          } else {

            // a normal publisher, pass it to sdk user.

            const publisher = {
              userId,
              userNumber,
              stream_props
            }

            this.emit('publisher', publisher);
          }
        }
      });
    });

    this.on('#unpublish', async (args) => {

      this._commandQueue.push({
        queueId: args.userId,
        task: async () => {

          this._sendToLogServer({
            type: 'sig-dw',
            data: {
              name: 'unpublish',
              args
            }
          });

          const userId = args.userId;
          const error = args.error;

          if (userId === this._userId) { // to publisher

            // must be a server-side-error.

            this.emit('rtcat-event', new RTCatEvent({
              code: RTCatEvent.EventCode.ROOT_SERVER_DOWN,
            }), userId);

            if (this._publisher) {
              // reset generation.
              this._publisher.generation = 0;
              return this._republish(this._publisher, 'server-down');
            }

          } else { // to subscriber

            const subscriber = this._subscribers.get(userId);

            if (error) {

              // a server-side-error unpublish notification.

              let reason = undefined;

              if (error.code === RTCatError.ErrorCode.ROUTER_DOWN) {

                this.emit('rtcat-event', new RTCatEvent({
                  code: RTCatEvent.EventCode.ROUTER_SERVER_DOWN,
                }), userId);

                reason = 'router-down';

                if (subscriber) {
                  // reset generation.
                  subscriber.generation = 0;
                  return this._resubscribe(subscriber, reason);
                }

              } else if (error.code === RTCatError.ErrorCode.ROOT_DOWN) {

                this.emit('rtcat-event', new RTCatEvent({
                  code: RTCatEvent.EventCode.ROOT_SERVER_DOWN,
                }), userId);

                reason = 'root-down';

                // a <publisher> notification will arrive later, 
                // so we will resubscribe it after it  .
              }

            } else {

              // a normal client-side unpublish notification.

              if (subscriber) {
                subscriber.removeAllListeners();
                subscriber.close();

                this._subscribers.delete(userId);
              }

              this.emit('unpublish', userId);
            }
          }
        }
      });
    });

    this.on('#mediaChanged', (args) => {

      this._commandQueue.push({
        queueId: args.userId,
        task: async () => {

          this._sendToLogServer({
            type: 'sig-dw',
            data: {
              name: 'mediaChanged',
              args
            }
          });

          const userId = args.userId;
          const userNumber = args.userNumber;
          const stream_props = args.stream_props;

          this.emit('media-changed', stream_props, userId, userNumber);
        }
      });
    });
  }

  /**
   * @throws RTCatError
   * @param {*} options 
   */
  async join(options) {

    // add as a sync task, so nothing can be done before join finished.
    return this._commandQueue.push({
      task: () => {
        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'join',
            uid: this._userId,
            options,
            platform: Detect.getPlatform(),
            device: Detect.getDevice(),
            domain: Detect.getDomain(),
            sdk: `web sdk ${SdkInfo.version}`
          }
        });

        return this._join(options);
      }
    });
  }

  /**
   * @throws RTCatError
   * @param {*} options 
   */
  async _join(options) {

    if (this.state === SessionUser.JOIN) {
      return;
    }

    if (this.state === SessionUser.QUIT) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'invalid state'
      });
    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args: {
        userNumber: this._userNumber,
        options
      }
    }

    try {
      const result = await this._request('join', params);

      this.stun_servers = result.stun_servers;
      this.area = result.area;

      this.state = SessionUser.JOIN;

    } catch (error) {

      const code = error.code;
      const name = error.message;
      const message = error.data ? error.data.detail : 'join request failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }
  }


  /**
   * @throws RTCatError
   */
  async quit() {

    this._commandQueue.clear({
      error: new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'stoped by <quit> request'
      })
    });

    // add as a sync task
    return this._commandQueue.push({
      task: () => {

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'quit',
            uid: this._userId
          }
        });

        return this._quit();
      }
    });
  }

  /**
   * @throws RTCatError
   */
  async _quit() {

    if (this.state !== SessionUser.JOIN) {
      return;
    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,
    }

    this.destroy();

    try {

      await this._request('quit', params, 120); // 120 ms

    } catch (error) {

      RTCatLog.W(`${this._sessionId}@${this._userId} quit request failed`);

    }
  }

  destroy(reason = QuitReason.Normal) {

    this._signalServer.internalEvents.removeListener('close', this._onSignalServerClose);

    this._destroyPublisher();
    this._destroySubscriber();

    this.state = SessionUser.QUIT;

    this.emit('quit', reason);

    this.removeAllListeners();
  }

  /*
   * set mirror
  */
  async setMirror(mode) {
    if (!this._publisher) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.NOT_EXIST,
        message: 'publisher does not exist'
      });
    }

    this._publisher.setMirror(mode);
  }

  /**
   * @throws define error type.
   * @param {*} stream
   * @param {*} options 
   */
  async publish(stream, options = {
    disableRR: true,
    server: null,
    cpuDetect: true,
    mirror: Peer.MirrorMode.NoMirror,
    audio,
    /*
     {
      sampleRate: 48000,
      bandwidth: 40, // kb
      stereo: false,
    }
     */
    video
    /*
    {
      codec: 'vp8',
      simulcast: false,
      bandwidth: 130, // kb 
      firPeriod: 15000,  // ms
      fps:15,
    }
    */
  }) {

    return this._commandQueue.push({
      queueId: this._userId,
      task: () => {

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'publish',
            options
          }
        });

        if (this.state !== SessionUser.JOIN) {

          throw new RTCatError({
            code: RTCatError.ErrorCode.LOCAL_GENERA,
            message: 'invalid state'
          });

        }

        if (this._publisher) {

          throw new RTCatError({
            code: RTCatError.ErrorCode.ALREADY_EXIST,
            name: 'ALREADY-EXIST',
            message: `${this._sessionId}@${this._userId} exist!`
          });
        }

        const publisher = new Publisher({
          userId: this._userId,
          stun_servers: this.stun_servers,
          stream,
          options
        });

        this._publisher = publisher;

        publisher.on('parsed-stats', stats => {
          this.emit('rtcat-stats', stats, this._userId);
        });

        publisher.on('low-fps', (fpsWant, fpsInput, fpsSent) => {
          this.emit('rtcat-event', new RTCatEvent({
            code: RTCatEvent.EventCode.LOW_FRAMERATE,
            data: {
              fpsWant,
              fpsInput,
              fpsSent
            }
          }), this._userId);
        });

        publisher.on('dynamic-stats', stats => {
          this._sendToLogServer({
            type: 'dynamic',
            data: stats
          });
        });

        publisher.on('flency-report', stats => {
          //RTCatLog.E(`${this._userId} publisher flency-report`);
          this.emit('flency-report', stats);
        });

        publisher.on('rtc-failed', () => {
          this._commandQueue.push({
            queueId: this._userId,
            task: () => {
              if (publisher.closed || publisher.connected) return;

              this._sendToLogServer({
                type: 'event',
                data: {
                  name: 'rtc-failed'
                }
              });

              // RTCatLog.I(`publisher ${this._sessionId}@${this._userId} rtc-failed`);

              this.emit('rtcat-event', new RTCatEvent({
                code: RTCatEvent.EventCode.RTC_FAILED,
              }), this._userId);

              return this._republish(publisher, 'rtc-failed');
            }
          });
        });

        publisher.on('rtc-connected', () => {
          this._commandQueue.push({
            queueId: this._userId,
            task: () => {
              if (publisher.closed) return;

              this._sendToLogServer({
                type: 'event',
                data: {
                  name: 'rtc-connected'
                }
              });

              publisher.generation = 0;

              // RTCatLog.I(`publisher ${this._sessionId}@${this._userId} rtc-connected`);

              this.emit('rtcat-event', new RTCatEvent({
                code: RTCatEvent.EventCode.RTC_CONNECTED,
              }), this._userId);
            }
          });
        });

        publisher.on('media-changed', (stream_props_update) => {
          this._commandQueue.push({
            queueId: this._userId,
            task: () => {
              if (publisher.closed) return;
              return this._changeMedia(stream_props_update).catch(() => {});
            }
          });
        });

        try {

          return this._publish();

        } catch (error) {

          this._destroyPublisher();

          throw error;
        }
      }

    });

  }

  /**
   * @throws define error type.
   * @param {*}
   */
  async _publish(operate, reason) {

    let result = null;

    const publisher = this._publisher;

    let args;

    try {

      const timeout = 10;

      args = await publisher.publish({
        operate,
        reason,
        timeout
      });

    } catch (error) {

      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'failed-to-get-sdp'
      });

    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args
    }

    try {

      result = await this._request('publish', params);

      publisher.rServer = result.server;

    } catch (error) {

      const code = error.code;
      const name = error.message;
      const message = error.data ? error.data.detail : 'publish request failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }

    try {

      await publisher.setSdp(result.sdp);

    } catch (error) {

      // should not happen, must be a bug.

      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'failed-to-set-remote-sdp'
      });

    }

    return result;
  }

  async unpublish(reason = 'normal') {

    return this._commandQueue.push({
      queueId: this._userId,
      task: () => {
        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'unpublish',
            reason
          }
        });

        return this._unpublish(reason);
      }
    });
  }

  async _unpublish(reason) {

    if (!this._publisher) {
      return;
    }

    this._destroyPublisher();

    if (this.state !== SessionUser.JOIN) {
      return
    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args: {
        reason
      }
    }

    try {

      await this._request('unpublish', params, 120); // 120 ms

    } catch (e) {

      RTCatLog.E(`unpublish: ${e.message} ${e.detail}`);

    }

  }


  /**
   * @throws define error type.
   * @param {*} stream_props 
   */
  async changeMedia(stream_props
    /*
    {
     audio: {
        kbps 
      } || boolean || undefine,
      video: {
        kbps
      } || boolean || undefine 
    } */
  ) {

    if (!stream_props) {
      return true;
    }

    stream_props = JSON.parse(JSON.stringify(stream_props));

    return this._commandQueue.push({
      queueId: this._userId,
      task: () => {

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'changeMedia',
            stream_props
          }
        });

        if (!this._publisher || !this._publisher.stream) {

          throw new RTCatError({
            code: RTCatError.ErrorCode.LOCAL_GENERA,
            message: 'stream dose not exist'
          });

        }

        const propsDiff = this._publisher.updateProps(stream_props);
        if (!propsDiff) {
          return;
        }

        return this._changeMedia(propsDiff);
      }
    });
  }

  async _changeMedia(propsDiff) {

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args: {
        stream_props: propsDiff
      }
    };

    try {

      await this._request('changeMedia', params);

    } catch (error) {

      const code = error.code;
      const name = error.message
      const message = error.data ? error.data.detail : 'change media req failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }
  }

  async switchArea(area) {
    return this._commandQueue.push({
      queueId: this._userId,
      task: () => {
        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'switchArea',
            area
          }
        });
        return this._switchArea(area);
      }
    });
  }

  async _switchArea(area) {
    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args: {
        area
      }
    };

    try {

      await this._request('switchArea', params);

      this.area = area;

    } catch (error) {

      const code = error.code;
      const name = error.message
      const message = error.data ? error.data.detail : 'switch area req failed';

      throw new RTCatError({
        code,
        name,
        message
      });

    }
  }

  async switchUpServer(server) {

    return this._commandQueue.push({
      queueId: this._userId,
      task: () => {
        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'switchUpServer',
            server
          }
        });
        return this._switchUpServer(server);
      }
    });
  }

  async _switchUpServer(server) {

    if (!this._publisher) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.NOT_EXIST,
        message: 'publisher does not exist'
      });
    }

    this._publisher.server = server;

    return this._publish("switch-server");
  }

  async switchDownServer(peer, server) {
    return this._commandQueue.push({
      queueId: peer.userId,
      task: () => {

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'switchDownServer',
            peer: peer.userNumber,
            pid: peer.userId,
            server
          }
        });

        return this._switchDownServer(peer, server);
      }
    });
  }

  async _switchDownServer(peer, server) {

    const subscriber = this._subscribers.get(peer.userId);

    if (!subscriber) {
      throw new RTCatError({
        code: RTCatError.ErrorCode.NOT_EXIST,
        message: 'subscriber does not exist'
      });
    }

    subscriber.server = server;

    return this._subscribe(subscriber, "switch-server");
  }

  /**
   * @throws RTCatError
   * @param {*} peer 
   * @param {*} options 
   */
  async subscribe(peer = {
      userId,
      userNumber
    },
    options = {
      cpuDetect: true,
      server: null,
      audio,
      /*
       {
        stereo: false
        }
       */
      video,
      /*
       {
       bandwidth: 3000 // kb
       }
       */
    }) {

    return this._commandQueue.push({
      queueId: peer.userId,
      task: async () => {

        const userId = peer.userId;
        const userNumber = peer.userNumber;

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'subscribe',
            peer: userNumber,
            pid: userId,
            options
          }
        });

        if (this.state !== SessionUser.JOIN) {
          throw new RTCatError({
            code: RTCatError.ErrorCode.LOCAL_GENERA,
            message: 'invalid-state'
          });
        }

        if (userId === this._userId) {
          throw new RTCatError({
            code: RTCatError.ErrorCode.LOCAL_GENERA,
            message: 'subscribe-loop'
          });
        }

        if (this._subscribers.has(userId)) {
          throw new RTCatError({
            code: RTCatError.ErrorCode.ALREADY_EXIST,
            name: 'ALREADY-EXIST',
            message: `${this._sessionId}@${userId} exist!`
          });
        }

        const stream = new RemoteStream(new MediaStream());

        const subscriber = new Subscriber({
          userId,
          userNumber,
          stun_servers: this.stun_servers,
          stream,
          options
        });

        subscriber.on('dynamic-stats', stats => {
          this._sendToLogServer({
            type: 'dynamic',
            feedId: userNumber, // peer
            data: stats
          });
        });

        subscriber.on('flency-report', stats => {
          //RTCatLog.E(`${userId} subscriber flency-report`);
          this.emit('flency-report', stats);
        });

        subscriber.on('rtc-failed', () => {
          this._commandQueue.push({
            queueId: userId,
            task: () => {
              if (subscriber.closed || subscriber.connected) return;

              this._sendToLogServer({
                type: 'event',
                data: {
                  name: 'rtc-failed',
                  peer: userNumber,
                  pid: userId
                }
              });

              // RTCatLog.I(`subscriber ${this._sessionId}@${userId} rtc-failed`);

              this.emit('rtcat-event', new RTCatEvent({
                code: RTCatEvent.EventCode.RTC_FAILED,
              }), userId);

              return this._resubscribe(subscriber, 'rtc-failed');
            }
          });
        });

        subscriber.on('rtc-connected', () => {
          this._commandQueue.push({
            queueId: userId,
            task: () => {
              if (subscriber.closed) return;

              this._sendToLogServer({
                type: 'event',
                data: {
                  name: 'rtc-connected',
                  peer: userNumber,
                  pid: userId
                }
              });

              subscriber.generation = 0;

              // RTCatLog.I(`subscriber ${this._sessionId}@${userId} rtc-connected`);

              this.emit('rtcat-event', new RTCatEvent({
                code: RTCatEvent.EventCode.RTC_CONNECTED,
              }), userId);
            }
          });
        });

        subscriber.on('parsed-stats', stats => {
          this.emit('rtcat-stats', stats, userId);
        });

        subscriber.on('massive-freeze', () => {
          this._commandQueue.push({
            queueId: userId,
            task: () => {
              if (subscriber.closed) return;

              this._sendToLogServer({
                type: 'event',
                data: {
                  name: 'massive-freeze',
                  peer: userNumber,
                  pid: userId
                }
              });

              // RTCatLog.I(`subscriber ${this._sessionId}@${userId} massive-freeze`);

              this.emit('rtcat-event', new RTCatEvent({
                code: RTCatEvent.EventCode.RTC_MASSIVE_FREEZE,
              }), userId);

              return this._resubscribe(subscriber, 'massive-freeze');
            }
          });
        });

        let result;

        try {

          result = await this._subscribe(subscriber);

          this._subscribers.set(userId, subscriber);

          result.stream = stream;

        } catch (error) {

          subscriber.removeAllListeners();
          subscriber.close();

          throw error;
        }

        return result;
      }

    });

  }

  /**
   * @throws RTCatError
   * @param {*} subscriber 
   * @param {*} options 
   */
  async _subscribe(subscriber, operate, reason) {

    let result;

    let args;

    try {

      args = await subscriber.subscribe({
        operate,
        reason
      })

    } catch (e) {

      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'failed-to-get-sdp'
      });
    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args
    };

    try {

      result = await this._request('subscribe', params);

      subscriber.rServer = result.server;

    } catch (error) {

      const code = error.code;
      const name = error.message;
      const message = error.data ? error.data.detail : 'subscribe request failed';

      throw new RTCatError({
        code,
        name,
        message
      });
    }

    try {

      await subscriber.rtcMesHandler({
        type: 'sdp',
        sdp: {
          type: 'answer',
          sdp: result.sdp
        }
      });

    } catch (error) {

      // should not happen, must be a bug.

      throw new RTCatError({
        code: RTCatError.ErrorCode.LOCAL_GENERA,
        message: 'failed to set remote sdp'
      });
    }

    const mutePeer = () => {
      if (subscriber.closed) return;
      return this.mutePeer(subscriber.muteMedia, subscriber.userId);
    }

    mutePeer().catch((e) => {
      RTCatLog.W('faile to mute peer, try it again', e);
      mutePeer();
    });

    return result;
  }

  async unsubscribe(userId, reason = 'normal') {

    return this._commandQueue.push({
      queueId: userId,
      task: () => {

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'unsubscribe',
            pid: userId,
            reason
          }
        });

        return this._unsubscribe(userId, reason);
      }
    });
  }

  async _unsubscribe(userId, reason) {

    if (!this._subscribers.get(userId)) {
      return;
    }

    this._destroySubscriber(userId);

    if (this.state !== SessionUser.JOIN) {
      return;
    }

    const params = {
      _sid: this._sessionId,
      _cid: this._userId,

      args: {
        feed: userId,
        reason
      }
    }

    try {

      await this._request('unsubscribe', params, 120); // 120 ms

    } catch (e) {

      RTCatLog.E(`unsubscribe: ${e.message} ${e.detail}`);

    }

  }

  async mutePeer(media, userId) {
    return this._commandQueue.push({
      queueId: userId,
      task: async () => {

        if (this.state !== SessionUser.JOIN) {
          throw new RTCatError({
            code: RTCatError.ErrorCode.LOCAL_GENERA,
            message: 'invalid state'
          });
        }

        this._sendToLogServer({
          type: 'api',
          data: {
            name: 'mutePeer',
            pid: userId
          }
        });

        const subscriber = this._subscribers.get(userId);
        if (!subscriber) {
          return;
        }

        if (media) {
          if (subscriber.muteMedia.video !== media.video) {
            subscriber.muteMedia.video = media.video;
          }
          if (subscriber.muteMedia.audio !== media.audio) {
            subscriber.muteMedia.video = media.audio;
          }
        }

        const params = {
          _sid: this._sessionId,
          _cid: this._userId,

          args: {
            feed: userId,
            media
          }
        }

        try {

          await this._request('mute', params);

        } catch (error) {

          const code = error.code;
          const name = error.message;
          const message = error.data ? error.data.detail : 'mute request failed';

          throw new RTCatError({
            code,
            name,
            message
          });
        }
      }
    });
  }

  _destroyPublisher() {

    if (this._publisher) {
      this._publisher.removeAllListeners();
      this._publisher.close();
      this._publisher = null;
    }
  }

  _destroySubscriber(id = null) {
    if (id) {
      const subscriber = this._subscribers.get(id);
      if (subscriber) {
        subscriber.removeAllListeners();
        subscriber.close();
        this._subscribers.delete(id);
      }
    } else {
      this._subscribers.forEach(subscriber => {
        subscriber.removeAllListeners();
        subscriber.close();
      });
      this._subscribers.clear();
    }
  }

  async _republish(publisher, reason = 'unknow') {
    if (!publisher || publisher.closed) {
      return;
    }

    if (publisher.reTimer) {
      return;
    }

    if (publisher.generation > 0) {
      let weight = publisher.generation <= 6 ?
        1 + 0.2 * publisher.generation :
        2.2 + Math.pow(publisher.generation - 6, 3);

      publisher.reTimer = setTimeout(() => {
        this._commandQueue.push({
          queueId: this._userId,
          task: async () => {
            return this._doRepublish(publisher, reason);
          }
        });
      }, 1000 * weight);
    } else {
      return this._doRepublish(publisher, reason);
    }
  }

  async _doRepublish(publisher, reason) {

    if (!publisher || publisher.closed) {
      return;
    }

    if (this.state !== SessionUser.JOIN) {
      // should not happen, must be a bug!
      RTCatLog.W(`republish ${this._userId} but session ${this._sessionId} is not joined`);
      this._destroyPublisher();
      return;
    }

    if (publisher.rServer) {
      publisher.server = {
        exclude: [publisher.rServer]
      };
    } else {
      publisher.server = undefined;
    }

    this.emit('rtcat-event', new RTCatEvent({
      code: RTCatEvent.EventCode.REPUBLISH_START,
      data: {
        reason
      }
    }), this._userId);

    if (publisher.reTimer) {
      clearTimeout(publisher.reTimer);
      publisher.reTimer = null;
    }

    try {

      await this._publish('republish', reason);

      this.emit('rtcat-event', new RTCatEvent({
        code: RTCatEvent.EventCode.REPUBLISH_END,
        data: {
          success: true,
          reason: 'success'
        }
      }), this._userId);

    } catch (error) {

      const code = error.code;

      this.emit('rtcat-event', new RTCatEvent({
        code: RTCatEvent.EventCode.REPUBLISH_ERROR,
        data: {
          error
        }
      }), this._userId);

      if (code !== RTCatError.ErrorCode.RPC_CLOSED) {

        this._commandQueue.push({
          queueId: this._userId,
          task: async () => {
            return this._republish(publisher, reason);
          }
        });

      }
    }
  }

  async _resubscribe(subscriber, reason = 'unknow') {
    if (!subscriber || subscriber.closed) {
      return;
    }

    const userId = subscriber.userId;

    if (subscriber.reTimer) {
      return;
    }

    if (subscriber.generation > 0) {
      let weight = subscriber.generation <= 6 ?
        1 + 0.2 * subscriber.generation :
        2.2 + Math.pow(subscriber.generation - 6, 3);

      subscriber.reTimer = setTimeout(() => {
        this._commandQueue.push({
          queueId: userId,
          task: async () => {
            return this._doResubscribe(subscriber, reason);
          }
        });
      }, 1000 * weight);

    } else {
      return this._doResubscribe(subscriber, reason);
    }
  }

  async _doResubscribe(subscriber, reason) {

    if (!subscriber || subscriber.closed) {
      return;
    }

    const userId = subscriber.userId;

    if (this.state !== SessionUser.JOIN) {
      // should not happen, must be a bug!
      RTCatLog.W(`resubscribe ${userId} but session ${this._sessionId} is not joined`);
      this._destroySubscriber();
      return;
    }

    if (subscriber.rServer) {
      subscriber.server = {
        exclude: [subscriber.rServer]
      };
    } else {
      subscriber.server = undefined;
    }

    this.emit('rtcat-event', new RTCatEvent({
      code: RTCatEvent.EventCode.RESUBSCRIBE_START,
      data: {
        reason
      }
    }), userId);

    if (subscriber.reTimer) {
      clearTimeout(subscriber.reTimer);
      subscriber.reTimer = null;
    }

    try {

      await this._subscribe(subscriber, 'resubscribe', reason);

      this.emit('rtcat-event', new RTCatEvent({
        code: RTCatEvent.EventCode.RESUBSCRIBE_END,
        data: {
          success: true,
          reason: 'success'
        }
      }), userId);

    } catch (error) {

      const code = error.code;

      this.emit('rtcat-event', new RTCatEvent({
        code: RTCatEvent.EventCode.RESUBSCRIBE_ERROR,
        data: {
          error
        }
      }), userId);

      if (code !== RTCatError.ErrorCode.RPC_CLOSED) {

        this._commandQueue.push({
          queueId: userId,
          task: async () => {
            return this._resubscribe(subscriber, reason);
          }
        });

      }
    }
  }


  async _request(method, params, timeout = 8000) {

    this._sendToLogServer({
      type: 'sig-up',
      data: {
        name: method,
        args: params.args
      }
    });

    let result;

    try {

      result = await this._signalServer.request(method, params, timeout);

    } catch (error) {

      this._sendToLogServer({
        type: 'sig-dw',
        data: {
          name: method,
          error
        }
      });

      throw error;
    }

    this._sendToLogServer({
      type: 'sig-dw',
      data: {
        name: method,
        result
      }
    });

    return result;
  }

  _sendToLogServer({
    type = 'event',
    feedId = null,
    data = null,
  }) {
    if (this._logServer) {
      this._logServer.sendLog(type, this._sessionId, this._userNumber, feedId, data);
    }
  }
}


SessionUser.INIT = 0;
SessionUser.JOIN = 1;
SessionUser.QUIT = 2;

SessionUser.QuitReason = QuitReason;


module.exports = SessionUser;
