class FrameDelayRate {
	construct() {
		this.delayInvalidCount = 0,
		this.delayOverCount = 0
	}

	addFrameDelay(framDelay) {
		if(framDelay == -1)
		{
			this.delayInvalidCount++;
			this.delayOverCount = 0;
		}
		else if(framDelay > 600)
		{
			this.delayInvalidCount = 0;
			this.delayOverCount++;
		}
		else
		{
			this.delayInvalidCount = 0;
			this.delayOverCount = 0;
		}
	}

	getFrameDelayStatus() {
		if(this.delayInvalidCount >= 7 || this.delayOverCount >= 15) {
			return true;
		}
		return false;
	}

}

module.exports = FrameDelayRate