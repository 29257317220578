const RTCatLog = require('../utils/log');
const Connection = require('../rtc/connection');
// const simulcastSDP = require('../rtc/simulcast');
const SdpUtils = require('../utils/sdp_utils');
const Peer = require('../rtc/peer');


const DEFAULT_BANDWIDTH = 1000 // 1Mb , unit 1kb
const MAX_BANDWIDTH = DEFAULT_BANDWIDTH * 80; // 80Mb

function setAudio(sdpString, sampleRate, bandwidth, enableStereo) {
  let opus_config = {
    'opusMaxPbr': sampleRate,
    'bandwidth': bandwidth * 1000,
    'opusStereo': enableStereo
  }

  RTCatLog.I(`audio config : `, JSON.stringify(opus_config));
  return SdpUtils.maybeSetOpusOptions(sdpString, opus_config);
}

function doDiffProps(newProps, oldProps) {

  if (typeof oldProps !== 'object') {
    return JSON.parse(JSON.stringify(newProps));
  }

  let propsDiff = {};

  for (const props in newProps) {

    if (newProps[props] === undefined) {
      continue;
    }

    if (typeof newProps[props] === 'object') {

      const tempPropsDiff = doDiffProps(newProps[props], oldProps[props]);

      if (tempPropsDiff) {
        propsDiff[props] = tempPropsDiff;
      }

    } else if (oldProps[props] !== newProps[props]) {

      propsDiff[props] = newProps[props];

    }
  }

  if (Object.keys(propsDiff).length === 0) {
    return undefined;
  }

  return propsDiff;
}

/**
 * 发布者，用于在SFU会话中发布音视频流
 */
class Publisher extends Connection {
  constructor({
    userId,
    stun_servers,
    stream,
    options
  } = {}) {
    super(userId, stun_servers, 'publisher');

    this.generation = 0;

    this.stream = stream;

    this.options = {
      ...options
    };

    this.audio = {
      sampleRate: 48000,
      bandwidth: 40,
      stereo: false
    };

    this.video = {
      codec: 'vp8',
      simulcast: false,
      bandwidth: 130, // kb test
      firPeriod: 15000 // ms
    }

    this.cpuDetect = true;
    this.disableRR = true;
    this.server = undefined;

    this.mirror = Peer.MirrorMode.NoMirror;

    this._setUp(options);
  }

  _setUp(options) {

    if (options && (typeof options === 'object')) {

      if (options.cpuDetect !== undefined) {
        this.cpuDetect = options.cpuDetect;
      }

      if (options.disableRR !== undefined) {
        this.disableRR = options.disableRR;
      }

      if (options.server !== undefined) {
        this.server = options.server;
      }

      if (options.mirror !== undefined) {
        this.mirror = options.mirror;
      }

      let audioOpts = options.audio;
      let videoOpts = options.video;

      if (audioOpts !== undefined) {

        if (audioOpts.sampleRate) {
          this.audio.sampleRate = audioOpts.sampleRate
        }

        if (audioOpts.bandwidth) {
          this.audio.bandwidth = audioOpts.bandwidth
        }

        if (audioOpts.stereo) {
          this.audio.stereo = audioOpts.stereo
        }
      }

      if (videoOpts !== undefined) {

        if (videoOpts.codec) {
          this.video.codec = videoOpts.codec
        }

        if (videoOpts.simulcast) {
          this.video.simulcast = videoOpts.simulcast
        }

        if (videoOpts.bandwidth) {
          this.video.bandwidth = videoOpts.bandwidth
        }

        if (videoOpts.firPeriod) {
          this.video.firPeriod = videoOpts.firPeriod
        }

        // check 

        if (this.video.codec !== 'vp8') {
          this.video.simulcast = false
        }

        if (!(this.video.bandwidth &&
            typeof this.video.bandwidth === 'number' &&
            this.video.bandwidth <= MAX_BANDWIDTH)) {
          this.video.bandwidth = DEFAULT_BANDWIDTH;
        }
      }
    }

    this._stream_props = {
      audio: this.audioProps,
      video: this.videoProps
    };

    this.onstreamupdate = () => {

      if (!this.stream) return;

      const diffProps = this.updateProps({
        audio: this.audioProps,
        video: this.videoProps
      });

      if (diffProps) {
        this.emit('media-changed', diffProps);
      }
    };

    this.stream.on('updateTracks', this.onstreamupdate);
    // this.stream.on('videomute', this.onstreamupdate);
    // this.stream.on('videounmute', this.onstreamupdate);
    // this.stream.on('videoended', this.onstreamupdate);
    // this.stream.on('audiomute', this.onstreamupdate);
    // this.stream.on('audiounmute', this.onstreamupdate);
    // this.stream.on('audioended', this.onstreamupdate);

    let fpsInput = undefined;
    let fpsSent = undefined;

    this.on('parsed-stats', stats => {

      if (this.videoProps) {
        if (fpsInput === undefined) {
          fpsInput = parseFloat(stats.video.fpsInput);
          fpsSent = parseFloat(stats.video.fps);
        } else {
          fpsInput = (fpsInput * 9 + parseFloat(stats.video.fpsInput)) / 10;
          fpsSent = (fpsSent * 9 + parseFloat(stats.video.fps)) / 10;
        }

        let alertLowFps = false;

        if (fpsInput - fpsSent > 4) {
          alertLowFps = true;
        }

        if (this.stream.type === this.stream.MediaDevice &&
          this.videoProps.fps - fpsInput > 4) {
          alertLowFps = true;
        }

        if (alertLowFps) {
          this.emit('low-fps', this.videoProps.fps, fpsInput, fpsSent);
        }
      }
    });

  }

  get audioProps() {
    const stream = this.stream;

    if (!stream ||
      !stream.audioTrack ||
      stream.audioTrack.readyState === 'ended' ||
      !stream.audioTrack.enabled ||
      stream.audioTrack.muted) {
      return false;
    }

    return {
      kbps: this.audio.bandwidth
    }
  }

  get videoProps() {
    const stream = this.stream;

    if (!stream ||
      !stream.videoTrack ||
      stream.videoTrack.readyState === 'ended' ||
      !stream.videoTrack.enabled ||
      stream.videoTrack.muted) {
      return false;
    }

    const newSetting = stream.videoTrack.getSettings();
    const frameRate = newSetting.frameRate;

    return {
      type: stream.type,
      fps: frameRate,
      kbps: this.video.bandwidth
    }
  }

  diffProps(newProps) {

    if (newProps === undefined) {
      return undefined;
    }

    return doDiffProps(newProps, this._stream_props);
  }

  updateProps(newProps) {

    const propsDiff = this.diffProps(newProps);
    if (!propsDiff) {
      return null;
    }

    const {
      audio,
      video
    } = propsDiff;

    const stream = this.stream;

    if (audio === false &&
      stream.hasAudio()) {
      stream.setAudioEnabled(false);
    } else if (audio !== undefined) {
      stream.setAudioEnabled(true);
    }

    if (video === false &&
      stream.hasVideo()) {
      stream.setVideoEnabled(false);
    } else if (video !== undefined) {
      stream.setVideoEnabled(true);
    }

    // update bandwidth

    if (video && video.kbps) {
      this.video.bandwidth = video.kbps;
    }

    if (audio && audio.kbps) {
      this.audio.bandwidth = audio.kbps;
    }

    if (propsDiff.audio === true) {
      propsDiff.audio = this.audioProps;
    }

    if (propsDiff.video === true) {
      propsDiff.video = this.videoProps;
    }

    this._stream_props = {
      audio: this.audioProps,
      video: this.videoProps
    };

    return propsDiff;
  }

  async setMirror(mode){
    this.check();
    
    this.peer.setMirror(mode);
  }

  async publish({
    operate = undefined,
    reason = 'normal',
    timeout = 10
  } = {}) {

    this.check();

    this.setupRtcPeer(this.cpuDetect);

    const offer = await this._pub(timeout);

    const uuid = Math.round(Math.random() * 100000000); // 1/100 000 000 collision probability.

    this.generation++;

    return {
      uuid,
      sdp: offer.sdp,

      options: {
        disableRR: this.disableRR,
        server: this.server,
        video: true,
        codec: this.video.codec,
        firPeriod: this.video.firPeriod,
        bandwidth: this.video.bandwidth,
        audio: true,
        stream_props: {
          audio: this.audioProps,
          video: this.videoProps
        },
        operate, // switch-server
        reason
      }
    }
  }

  // local sdp handler
  _sdpHandler(offer) {

    let sdp = offer.sdp;
    // if (this.simulcast) {
    //   RTCatLog.I('enable simulcast!');
    //   sdp = simulcastSDP(sdp);
    // }

    sdp = SdpUtils.opusNACK(sdp);

    return {
      type: 'offer',
      sdp
    };
  }

  async _pub(timeout = 10) {

    this.setTimer(timeout);

    return this.peer.offer(this.stream, this.mirror, null, sdp => {
      return this._sdpHandler(sdp);
    });

  }

  async setSdp(sdp) {

    this.check();

    // sdp = SdpUtils.setVideoSendBitRate(sdp,
    //   0,
    //   0,
    //   this.video.bandwidth << 10);

    sdp = setAudio(sdp,
      this.audio.sampleRate,
      this.audio.bandwidth,
      this.audio.stereo);

    // RTCatLog.I(`setSdp:`, JSON.stringify(sdp));

    return this.rtcMesHandler({
      type: 'sdp',
      sdp: {
        type: 'answer',
        sdp
      }
    });

  }

  close() {
    if (this.stream) {
      // https://nodejs.org/api/events.html
      /**
       * any removeListener() or removeAllListeners() calls after emitting and before the last listener 
       * finishes execution will not remove them from emit() in progress. Subsequent events will behave 
       * as expected.
       */
      this.stream.removeListener('updateTracks', this.onstreamupdate);
      // this.stream.removeListener('videomute', this.onstreamupdate);
      // this.stream.removeListener('videounmute', this.onstreamupdate);
      // this.stream.removeListener('videoended', this.onstreamupdate);
      // this.stream.removeListener('audiomute', this.onstreamupdate);
      // this.stream.removeListener('audiounmute', this.onstreamupdate);
      // this.stream.removeListener('audioended', this.onstreamupdate);
      this.stream = null;
    }

    super.close();
  }
}


module.exports = Publisher;